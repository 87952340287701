import Calendar from "@demark-pro/react-booking-calendar";
import { useNavigate } from "react-router-dom";
// import "@demark-pro/react-booking-calendar/dist/";
import { useState } from "react";

export const BoatHeaderComponent = ({
  boat,
  locationArr,
  selected,
  reserved,
  setSelected,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const useBooking = (e) => {
    e.preventDefault();
    if (selected === undefined || selected.length === 0) {
      const error_message = document.getElementById("error-message");
      // error_message.style.display = "grid";
      // error_message.style.gridTemplateColumns = "repeat(2, minmax(0, 1fr))";
      error_message.style.display = "block";
      return setError("You must select a booking date");
    }
    navigate(`/booking/${boat.url_name}`, {
      state: { boat: boat, dates: selected },
    });
  };
  const handleCloseError = (e) => {
    e.preventDefault();
    document.getElementById("error-message").style.display = "none";
  };
  const handleMapToggle = (e) => {
    e.preventDefault();
    const mapElement = document.getElementById("map");
    return mapElement.style.display === "block"
      ? (mapElement.style.display = "none")
      : (mapElement.style.display = "block");
  };

  return (
    <>
      <div>
        <h2 className="text-xl font-bold">{boat?.name}</h2>
        <div className="flex items-center text-blue-500">
          <span className="mr-2">{boat?.reviews?.rating || ``}</span>
          <span className="text-yellow-500">
            {boat?.reviews?.star_count || ``}
          </span>
        </div>
        <p className="text-gray-600">
          {locationArr[0]} {", "}
          <button className="text-blue-500" onClick={(e) => handleMapToggle(e)}>
            {locationArr[1]}
          </button>
        </p>
        <span
          id="error-message"
          className="bg-red-600 p-1 rounded-xl text-center hidden text-white"
        >
          {error}
          <button
            id="close-error-message"
            className="ml-2"
            onClick={handleCloseError}
          >
            <svg
              fill="#000000"
              height="12px"
              width="12px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 460.775 460.775"
              xmlSpace="preserve"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path>{" "}
              </g>
            </svg>
          </button>
        </span>
        <div
          className="map z-20 bg-gray-200 pl-2 pr-2 m-0 pb-0 rounded-lg"
          style={{ display: "none", position: "absolute" }}
          id="map"
        >
          <p className="text-right w-full">
            <button onClick={(e) => handleMapToggle(e)}>
              <svg
                fill="#000000"
                height="12px"
                width="12px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 460.775 460.775"
                xmlSpace="preserve"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path>{" "}
                </g>
              </svg>
            </button>
          </p>

          <iframe
            className="bg-gray-200 p-2"
            title="Google Maps Embed"
            width={300}
            height={250}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJz4a_WoO-oRQRhmi0DaQ8keg&key=${process.env.REACT_APP_MAPS_API}`}
          ></iframe>
        </div>

        <form onSubmit={useBooking} className="max-w-prose">
          <div className="max-w-prose ">
            <Calendar
              selected={selected}
              reserved={reserved}
              range={true}
              protection="true"
              onChange={setSelected}
              onOverbook={(_, type) => setError(type)}
              disabled={(date, state) => date.getDay() !== 6 || state.isPast}
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 rounded-lg p-4 w-full text-center text-white font-bold"
          >
            Pre-book now!
          </button>
        </form>
      </div>
    </>
  );
};
